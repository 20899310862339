import { Controller } from "stimulus"
import { get, post } from "@rails/request.js"
import "form-request-submit-polyfill"

export default class FoxpostController extends Controller {
  static targets = ["foxApprove"]


  change(event) {
    let order_id = event.target.id
    this.get_data(order_id)
    // console.log("clicked")
  }


  async get_data(order_id) {
    let response = await post('/couriers/foxpost/approve_order', {
      body: JSON.stringify({ approve_order: order_id }),
      responseKind: "json"
    })
    let setting = await response.json
    // console.log(setting)
  }

}

