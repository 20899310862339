import { Controller } from "stimulus"
import { get } from "@rails/request.js"
import "form-request-submit-polyfill"


export default class ProductOrderController extends Controller {
  static targets = ["orderData"]
  
  
  connect() {
  }
  
  
  async display(event) {
    let order = event.target.value
    let response = await get(`/line_items/find_order?orderId=${order}`,{
      responseKind: "json"
    })
    let order_data = await response.json
    this.show_order(order_data)
  }
  
  
  show_order(order_data) {
    this.orderDataTarget.innerHTML = ""
    if (order_data.status == "not available") {
      this.order_na(order_data)
    } else {
      this.order(order_data)
    }
  }
  
  
  order_na(order_data) {
    const paragraph = document.createElement("p");
    paragraph.innerHTML = "Nincs ilyen rendelés"
    this.orderDataTarget.appendChild(paragraph);
  }
  
  
  order(order_data) {
    const paragraph = document.createElement("p");
    paragraph.innerHTML = `Rendelés szám: ${order_data.order_id} <br> 
                            - ${order_data.quantity} db <br>
                            - ${order_data.shape} <br>
                            - ${order_data.jewelry_type} <br>
                            - ${order_data.material_type} <br>                            
                            Státusz: ${order_data.status} <br>
                            Ötvös: ${order_data.goldsmith}`
    this.orderDataTarget.appendChild(paragraph);
  }
  
}

