import { Controller } from "stimulus"
import { get } from "@rails/request.js"
import "form-request-submit-polyfill"

export default class PriceFormController extends Controller {
  static targets = ["priceGroup", "submitButton", "jewelryType", "materialType"]


  convert_material(material_hu) {
    if (material_hu == 1) {
      return "silver"
    } else if (material_hu == 2) {
      return "gold"
    } else if (material_hu == 3) {
      return "gold"  
    } else if (material_hu == 4) {
      return "gold"
    } else {
      return "unidentified material"
    }
  }
  
  connect() {
    let jewelryType = this.jewelryTypeTarget.selectedOptions[0].value
    let materialType = this.convert_material(this.materialTypeTarget.selectedOptions[0].value)
    this.get_data(jewelryType, materialType)
  }
  
  changeJewelry(event) {
    let jewelryType = event.target.selectedOptions[0].value
    let materialType = this.convert_material(this.materialTypeTarget.selectedOptions[0].value)
    this.get_data(jewelryType, materialType)
  }
  
  changeMaterial(event) {
    let materialType = this.convert_material(event.target.selectedOptions[0].value)
    let jewelryType = this.jewelryTypeTarget.selectedOptions[0].value
    this.get_data(jewelryType, materialType)
  }
    
    
  async get_data(jewelryType, materialType) {
    let response = await get(`/prices/price_groups?jewelryType=${jewelryType}&materialType=${materialType}`, {
      responseKind: "json"
    })
    let priceGroups = await response.json
    this.manageSelect(priceGroups)
  }


  manageSelect(priceGroups){
    let numberOfItems = priceGroups.length
    this.priceGroupTarget.innerHTML = "";
    if (numberOfItems == 0) {
      this.noGroups()
    } else {
      this.updateSelect(priceGroups)
    }
  }


  updateSelect(priceGroups) {
    priceGroups.sort((a,b) => a.price > b.price)
    priceGroups.forEach((group) => {
      const option = document.createElement("option");
      option.value = group.id;
      option.innerHTML = group.name;
      this.priceGroupTarget.appendChild(option);
    });
    this.submitButtonTarget.disabled = false;
  }


  noGroups() {
    const option = document.createElement("option");
    option.innerHTML = "Nincs ár csoport";
    this.priceGroupTarget.appendChild(option);
    this.submitButtonTarget.disabled = true;
  }
  
}

